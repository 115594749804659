/**
 * NOTE: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, comment the corresponding function calls here.
 */

import * as application from './application/index.js';
import * as polyfills from '@spinnwerk/polyfills/index.js';
import { initFoundation } from './_foundation.js';
import { fullHeightOnMobile } from './utils/full-height-on-mobile.js';
import { deviceOrientation } from './utils/device-orientation.js';
import { initSecuringExternalAnchors } from './security/external-anchors.js';
import { loadFonts } from '../../components/base/fonts/fonts.js';
import { sectionNav } from '../../components/patterns/molecules/navigation/index.js';
import * as form from '../../components/patterns/molecules/form/index.js';
import { animateSVG } from '../../components/patterns/atoms/animate/index.js';
import { scrollUp } from '../../components/patterns/molecules/scroll-up/scroll-up.js';
import { initHeadroom } from '../../components/patterns/molecules/headroom/index.js';
import { fontSizeSwitcher } from '../../components/patterns/molecules/font-size-switcher/index.js';
import { initPrimaryNav } from '../../components/patterns/molecules/navigation/primary-nav/index.js';
import { callout } from '../../components/patterns/molecules/callout/callout.js';
import { cards } from '../../components/patterns/molecules/card/cards.js';
import { firstLastClasses } from './utils/first-last-classes.js';
import { counter } from '../../components/patterns/molecules/counter/index.js';
import { filters, searches } from '../../components/patterns/molecules/filter/index.js';
import { lightbox } from '../../components/patterns/molecules/lightbox/index.js';
import { masonry } from '../../components/layout/masonry/index.js';
import { swiper } from '../../components/layout/swiper/index.js';
import { maps } from '../../components/layout/map/index.js';
import { manageCookieConsent } from '../../components/patterns/molecules/cookie-consent/index.js';
import { share } from '../../components/patterns/organisms/share/index.js';
import { popUp } from '../../components/patterns/molecules/pop-up/popUp.js';
import { shop } from '../../components/shop/index.js';
import { initOffCanvas } from '../../components/patterns/molecules/navigation/off-canvas/off-canvas.js';
import { courses } from '../../components/patterns/organisms/course/index.js';

// breakpoint on which headroom should be active
// NOTE: has to match breakpoint in resources/components/patterns/molecules/headroom/_headroom.scss
const breakpoint = 'small';

// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
application.bugsnag();

// ////////////////////////////////////////////////////////////
// add .no-cookie class to <html/> if cookies are disabled
document.documentElement.classList.toggle('no-cookies', navigator.cookieEnabled !== true);

// ////////////////////////////////////////////////////////////
// include critical polyfills very early
polyfills.focusOptions();
polyfills.dialog();
polyfills.inert();

// ////////////////////////////////////////////////////////////
// image lazy loading
import(/* webpackMode: "lazy" */ 'lazysizes');

// enable support for native lazy loading
/* NOTE: native lazy loading has two major downsides:
 * it loads images much earlier than lazysizes when scrolling down and has no possibility to adjust this value
 * and it loads *all* images above or near the 'fold' regardless of their current visibility state (e.g. in sliders/accordions etc).
 * See https://web.dev/native-lazy-loading#how-does-the-loading-attribute-work-with-images-that-are-in-the-viewport-but-not-immediately-visible-(for-example-behind-a-carousel)
 * If your project is fine with this, feel free to enable the following code and remove the above import:
// import lazySizes from 'lazysizes';
// import 'lazysizes/plugins/native-loading/ls.native-loading';
// lazySizes.cfg.nativeLoading = {
//     setLoadingAttribute: true,
//     disableListeners: true, // remove all event listeners added by lazysizes in browsers that support native lazy loading
// };
 */

// ////////////////////////////////////////////////////////////
// Framework initialization
initFoundation();

// ////////////////////////////////////////////////////////////
// Initialize Off-Canvas components
initOffCanvas();

// ////////////////////////////////////////////////////////////
// Hack vor having full 100vh support on mobile devices
fullHeightOnMobile();

// ////////////////////////////////////////////////////////////
// Adds custom classes based on device orientation
deviceOrientation();

// ////////////////////////////////////////////////////////////
// Security
initSecuringExternalAnchors();

// ////////////////////////////////////////////////////////////
// Application setup

// handle service workers
application.serviceWorker({ unregister: true });

// optimized font loading
// fonts are defined in app/etc/.fontsrc.json and automatically added to CONFIG
loadFonts(CONFIG.fonts);
delete CONFIG.fonts; // release memory, fonts are not needed anymore

// ux / accessibility features
application.handleTabKey();

// init all scrolling belonging
application.autoScrolling({
    fromHashChanges: CONFIG.scrollFromHashChanges,
    offset: {
        small: 0,
        [breakpoint]: {
            up: '.header',
            down: 0,
        },
    },
    getTargetId(hash) {
        return (
            hash
                .replace(/#\/?/, '')
                // escape url-encoded hashes properly to form a valid CSS selector required to find the target element
                .replaceAll('%', '\\%')
        );
    },
    beforeScroll(target, $target) {
        /* eslint-disable jquery/no-is, jquery/no-closest */
        // jQuery is required to open targeted Accordion-items anyways, so it is fine to use it */
        if ($target.is('[data-tab-content]')) {
            $target.closest('[data-accordion]').foundation('down', $target);
        }
        /* eslint-enable */
    },
});

// hide elements when others appear
application.clearAway({
    target: '.section-nav',
    trigger: '.footer',
});

// ////////////////////////////////////////////////////////////
// Navigation

// update URL and state links based on scrolling
sectionNav({
    getHashFromElement(el) {
        return '/' + (el.dataset.sectionNavAnchor || el.id);
    },
    getActiveStateSelector(hash) {
        return `[href="#${hash.replace(/^#?\//, '')}"]`;
    },
});

// ////////////////////////////////////////////////////////////
// form helpers
function initFormHelpers() {
    // enable validation
    form.validation().then((parsleyOptions) => {
        form.steps(); // validation needs to be in place before multi-step forms can be initialized

        // automatically attach a loading indicator to submit buttons on form submit
        // but make sure, the validation is attached before
        form.loadingIndicator({
            exclude: '.search-control [type="submit"], button[name="apply_coupon"]',
        });

        // correctly validate shop checkout
        form.shopCheckoutValidation(parsleyOptions);

        // correctly validate forms present on 'my account' page
        form.shopMyAccountValidation(parsleyOptions);
    });

    // enable automatic summaries
    form.summaries();

    // enable conditional inputs
    form.conditionals();

    // enable (de-)selecting all checkboxes at once
    form.selectAll();

    // style advanced file inputs
    form.fileInput({
        multipleFilesLabel: ':anz Dateien ausgewählt',
    });

    // enable multi file uploads
    form.multiUpload();

    // manage hierarchical checkboxes
    form.hierarchicalCheckboxes();

    // init functionality for search-inputs
    form.searchInputs();
}

initFormHelpers();

// WooCommerce replaces DOM completely, so all functionality must be applied again (-> missing event handlers)
document.body.addEventListener('updated_wc_div', () => initFormHelpers(), { passive: true });

// ////////////////////////////////////////////////////////////
// atoms
animateSVG();

// ////////////////////////////////////////////////////////////
// molecules
scrollUp({
    createButton:
        'createScrollUpButton' in CONFIG ? CONFIG.createScrollUpButton : !document.querySelector('.section-nav'),
});

application.globalState.set(
    'headroom',
    initHeadroom({
        // add the spacer only if there is no hero element
        prependSpacerTo: document.querySelector('.wrapper .teaser--hero') ? false : '[data-main]',
        breakpoint,
        offset: {
            small: 0,
            [breakpoint]: {
                up: '[data-headroom-offset]',
                down: 0,
            },
        },
        onSpacerUpdate(spacing) {
            document.querySelectorAll('[data-headroom-update]').forEach((el) => {
                const property = el.dataset.headroomUpdate,
                    computedStyles = window.getComputedStyle(el);

                el.style[property] = '';

                let value = parseInt(computedStyles.getPropertyValue(property), 10);

                el.style[property] = value + spacing + 'px';
            });
        },
    }),
);

fontSizeSwitcher();

initPrimaryNav();

callout();

cards();

// the items in the footer navigation and the items in the container needs to be checked separately
firstLastClasses('.footer__small__nav > *');
firstLastClasses('.footer__small__nav .menu__item');
firstLastClasses('.filter__term', '.filter', true);

counter();

searches().then(() => {
    // filters need to be initialized after searches
    // in order to maintain correct initial values
    filters();
});

lightbox();

// ////////////////////////////////////////////////////////////
// layouts
masonry();

swiper();

maps();

// ////////////////////////////////////////////////////////////
// browser alerts
manageCookieConsent();

// ////////////////////////////////////////////////////////////
// init web share
share();

// ////////////////////////////////////////////////////////////
// init pop-ups
popUp();

// ////////////////////////////////////////////////////////////
// init shop specific scripts
shop();

courses();
